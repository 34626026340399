import mapObjIndexed from "ramda/src/mapObjIndexed";

import configGet from "../../config";

function EncodedInfo(fn) {
  return (info) =>
    fn(mapObjIndexed((i) => (i ? encodeURIComponent(i) : null), info));
}

function initFacebookSdk(facebookURL) {
  return new Promise(() => {
    // wait for facebook sdk to initialize before starting the react app
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: configGet("facebookAppID"),
        cookie: true,
        xfbml: true,
        version: "v8.0",
      });

      window.FB.ui({
        method: "share",
        display: "popup",
        href: facebookURL,
        hashtag: "#Podchaser",
      });
    };

    // load facebook sdk script
    (function (d, s, id) {
      const fjs = d.getElementsByTagName(s)[0];

      if (!d.getElementById(id)) {
        const js = d.createElement(s);

        js.id = id;
        js.defer = true;
        js.src = "//connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      }
    })(document, "script", "facebook-jssdk");
  });
}

const sharing = {
  Links: {
    Facebook: EncodedInfo(
      (info) => `https://www.facebook.com/sharer/sharer.php?u=${info.url}`
    ),
    Twitter: EncodedInfo(
      (info) =>
        `https://twitter.com/intent/tweet?url=${info.url}&text=${info.title}${
          info.twitterHandle ? `%20cc%20@${info.twitterHandle}` : ""
        }${info.useVia ? "&via=Podchaser" : ""}${
          info.twitterHandle ? `&related=${info.twitterHandle}` : ""
        }`
    ),
    GooglePlus: EncodedInfo(
      (info) => `https://plus.google.com/share?url=${info.url}`
    ),
    Pinterest: EncodedInfo(
      (info) =>
        `http://pinterest.com/pin/create/button/?url=${info.url}&media=${info.image_url}&description=${info.title}`
    ),
    LinkedIn: EncodedInfo(
      (info) =>
        `https://www.linkedin.com/shareArticle?mini=true&url=${info.url}&title=Podchaser&summary=&source=`
    ),
    Tumblr: EncodedInfo(
      (info) => `http://www.tumblr.com/share?v=3&u=${info.url}&t=${info.title}`
    ),
    Reddit: EncodedInfo(
      (info) =>
        `https://www.reddit.com/submit?url=${info.url}&title=${info.title}`
    ),
  },
  Actions: {
    Facebook: (info) => () => {
      initFacebookSdk(info.url);
    },
    NewWindowAction: (url) => () =>
      window.open(url, "shareWindow", "height=450, width=550"),
  },
};

export default sharing;
