import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, Fragment, useCallback, useMemo, useState } from "react";
import { sendDirectRecommendationPromise } from "routines/user";
import isEmailValidator from "validator/lib/isEmail";

import PageSidebarPanel from "components/Entities/Page/PageSidebarPanel";
import Title from "components/Entities/Page/Title";
import EntitySharingLinks from "components/Sharing/EntitySharingLinks";

import DirectRecommendationsPanelInputArea from "./DirectRecommendationsPanelInputAreaAsync";
import DirectRecommendationsPanelSearch from "./DirectRecommendationsPanelSearch";

import paginationActions from "actions/pagination";
import { getBaseUrl } from "constants/base";
import { entityNames } from "constants/entity";
import getEntityImageUrl from "utils/entity/getEntityImageUrl";
import getUserDisplayName from "utils/entity/getUserDisplayName";
import { capitalize } from "utils/misc";

import useActionCreators from "hooks/useActionCreators";
import { useLoggedIn } from "hooks/useLoggedInUser";
import useRoutinePromises from "hooks/useRoutinePromises";

import ScreenSizes from "styles/ScreenSizes";

const entitySharingLinkStyles = {
  entitySharingLinks: {
    width: "100%",
  },
  list: {
    padding: 0,
  },
  item: {
    [ScreenSizes.smAndMd]: {
      padding: "0 0.5rem",
    },
    [ScreenSizes.lgAndAbove]: {
      maxWidth: 65,
    },
    ":first-child": {
      paddingLeft: 0,
    },
    ":last-child": {
      paddingRight: 0,
    },
  },
  variationWhiteLink: {
    borderRadius: 4,
    height: "2.625rem",
    padding: 0,

    [ScreenSizes.lgAndAbove]: {
      height: "2.375rem",
    },
  },
  icon: {
    fontSize: "1.35rem",
    marginTop: 2,

    [ScreenSizes.lgAndAbove]: {
      fontSize: "1.25rem",
    },
  },
};

const entitySharingAutoWidthLinkStyles = {
  ...entitySharingLinkStyles,
  item: {
    ...entitySharingLinkStyles.item,
    [ScreenSizes.lgAndAbove]: {
      maxWidth: "inital",
    },
  },
};

const DIRECT_RECS_SEARCH_KEY = "direct_recommendation_search";

const getSearchTerm = (term) => {
  if (term && term.charAt(0) === "@") {
    return term.slice(1);
  }

  return term;
};

const translateErrorCode = (code, { entity_type, user, emailOnly }) => {
  if (emailOnly) {
    return "We were unable to send your invite";
  }

  switch (code) {
    case "TARGET_USER_NOT_FOLLOWING":
      return "You may only recommend to a user who is following you.";
    case "USER_ALREADY_RECOMMENDED": {
      const usersName = user ? getUserDisplayName(user) : "this user";

      return `You have already sent a recommendation to ${usersName} for this ${entity_type}.`;
    }
    default:
      return "We were unable to submit your recommendation";
  }
};

const DirectRecommendationsPanel = (props) => {
  const {
    noPanel,
    title: passedTitle,
    entity_type,
    entity,
    panelStyles,
    sharingLinksInfo: passedSharingLinksInfo,
    searchPlaceholder,
    searchPlaceholderLoggedOut,
    noTitle,
    itemWidthAuto,
    getName,
    getEntityUrl,
  } = props;
  const [search_term, setStateSearchTerm] = useState("");

  const { sendDirectRecommendation } = useRoutinePromises({
    sendDirectRecommendation: sendDirectRecommendationPromise,
  });
  const { setSearchTerm } = useActionCreators({
    setSearchTerm: paginationActions.setSearchTerm,
  });

  const [user, setUser] = useState(null);
  const [email, setEmail] = useState(null);
  const [recommendationSendSuccessful, setRecommendationSendSuccessful] =
    useState(false);
  const [message, setMessage] = useState("");
  const [showSubmitStage, setShowSubmitStage] = useState(false);
  const [sending, setSending] = useState(false);
  const [error, setError] = useState(null);

  const isLoggedIn = useLoggedIn();
  const emailOnly = !isLoggedIn;

  const isEmail = useMemo(() => {
    if (emailOnly) {
      return isEmailValidator(search_term);
    }

    const trimmed = search_term ? search_term.trim() : "";

    return trimmed.charAt(0) !== "@" && trimmed.includes("@");
  }, [search_term, emailOnly]);

  const handleSearchChange = useCallback(
    (newSearchTerm) => {
      setStateSearchTerm(newSearchTerm);

      // this will trigger the actual search
      if (!isEmail && !emailOnly && newSearchTerm !== "") {
        setSearchTerm(DIRECT_RECS_SEARCH_KEY, getSearchTerm(newSearchTerm));
      }
    },
    [isEmail, emailOnly, setSearchTerm]
  );

  const resetRecommendation = useCallback(() => {
    setShowSubmitStage(false);
    setStateSearchTerm("");
    setEmail(null);
    setUser(null);
    setMessage("");
    // don't do setSearchTerm here because we don't want to trigger an empty searc
  }, []);

  const sharingLinksInfo = useMemo(() => {
    if (passedSharingLinksInfo) {
      return passedSharingLinksInfo;
    }

    const name = getName(entity, entity_type);

    return {
      url: `${getBaseUrl()}${getEntityUrl(entity)}`,
      title: `${name} on Podchaser`,
      twitterTitle: `${name} on @Podchaser`,
      image_url: `${getEntityImageUrl(entity, entity_type)}`,
      twitterHandle: (entity && entity.get("twitter_handle")) || null,
    };
  }, [entity, entity_type, passedSharingLinksInfo, getEntityUrl, getName]);

  const title =
    passedTitle || `Share This ${capitalize(entityNames[entity_type])}`;

  const titleContent = useMemo(() => {
    if (noTitle) {
      return null;
    }
    return <Title title={title} TitleComponent="h3" inSidebar />;
  }, [title, noTitle]);

  const handleSelectUser = useCallback((selectedUserResult) => {
    if (selectedUserResult) {
      setUser(selectedUserResult.get("_source"));
      setShowSubmitStage(true);
    }
  }, []);
  const handleClearUser = useCallback(resetRecommendation, []);

  const handleSearchTermKeyDown = useCallback(
    (e) => {
      const keycode = e.keyCode ? e.keyCode : e.which;

      if (keycode === 13 && isEmail) {
        // is enter
        setEmail(search_term);
        setShowSubmitStage(true);
      }
    },
    [isEmail, search_term]
  );

  const handleSelectEmail = useCallback(() => {
    setEmail(search_term);
    setShowSubmitStage(true);
  }, [search_term]);

  const handleMessageChange = useCallback(
    (newMessage) => setMessage(newMessage.target.value),
    []
  );

  const handleSendRecommendation = useCallback(() => {
    const trimmedMessage = message.trim();
    setSending(true);
    sendDirectRecommendation({
      entity_type,
      entity_id: entity && entity.get("id"),
      entity,
      user_id: user && user.get("id"),
      username: user && user.get("username"),
      email,
      message: trimmedMessage || null,
    })
      .then((response) => {
        if (!response.cancelled) {
          resetRecommendation();
          setRecommendationSendSuccessful(true);
          setTimeout(() => setRecommendationSendSuccessful(false), 3000);
        }
      })
      .catch((e) => {
        setError(
          translateErrorCode(e.error, {
            entity_type,
            user,
            emailOnly,
          })
        );
      })
      .finally(() => {
        setSending(false);
      });
  }, [
    message,
    sendDirectRecommendation,
    entity_type,
    entity,
    user,
    email,
    resetRecommendation,
    emailOnly,
  ]);

  const renderInputArea = () => {
    if (showSubmitStage) {
      return (
        <DirectRecommendationsPanelInputArea
          onMessageChange={handleMessageChange}
          onSendRecommendation={handleSendRecommendation}
          onClearUser={handleClearUser}
          email={email}
          sending={sending}
          error={error}
          user={user}
          isEmail={isEmail}
        />
      );
    }

    return (
      <DirectRecommendationsPanelSearch
        searchListKey={DIRECT_RECS_SEARCH_KEY}
        onSearchChange={handleSearchChange}
        onSearchTermKeyDown={handleSearchTermKeyDown}
        onSelectUser={handleSelectUser}
        onSelectEmail={handleSelectEmail}
        onResetRecommendation={resetRecommendation}
        search_term={search_term}
        placeholder={
          isLoggedIn ? searchPlaceholder : searchPlaceholderLoggedOut
        }
        recommendationSendSuccessful={recommendationSendSuccessful}
        isEmail={isEmail}
        emailOnly={emailOnly}
      />
    );
  };

  const content = (
    <>
      {titleContent}
      {renderInputArea()}
      <EntitySharingLinks
        entity={entity}
        entity_type={entity_type}
        info={sharingLinksInfo}
        variation="white"
        styles={
          itemWidthAuto
            ? entitySharingAutoWidthLinkStyles
            : entitySharingLinkStyles
        }
      />
    </>
  );

  if (noPanel) {
    return <Fragment>{content}</Fragment>;
  }

  return (
    <PageSidebarPanel {...props} styles={panelStyles}>
      {content}
    </PageSidebarPanel>
  );
};

DirectRecommendationsPanel.propTypes = {
  entity_type: PropTypes.string,
  entity: PropTypes.instanceOf(Map),
  noPanel: PropTypes.bool,
  title: PropTypes.string,
  panelStyles: PropTypes.object,
  sharingLinksInfo: PropTypes.object,
  searchPlaceholder: PropTypes.string,
  searchPlaceholderLoggedOut: PropTypes.string,
  emailOnly: PropTypes.bool,
  noTitle: PropTypes.bool,
  itemWidthAuto: PropTypes.bool,
  getName: PropTypes.func,
  getEntityUrl: PropTypes.func,
};

DirectRecommendationsPanel.defaultProps = {
  entity_type: null,
  entity: Map({}),
  noPanel: false,
  title: null,
  panelStyles: null,
  sharingLinksInfo: null,
  searchPlaceholder: "Email address or username...",
  searchPlaceholderLoggedOut: "Email address...",
  emailOnly: false,
  noTitle: false,
  itemWidthAuto: false,
  getName: null,
  getEntityUrl: null,
};

export default memo(DirectRecommendationsPanel);
