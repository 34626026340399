import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

const DirectRecommendationsPanelInputArea = Loadable(() =>
  import("./DirectRecommendationsPanelInputArea")
);

function DirectRecommendationsPanelInputAreaAsyncLoading(props) {
  return <LoadingOverlay {...props} span />;
}

const DirectRecommendationsPanelInputAreaContainer = (props) => {
  return (
    <DirectRecommendationsPanelInputArea
      {...props}
      fallback={<DirectRecommendationsPanelInputAreaAsyncLoading />}
    />
  );
};

export default DirectRecommendationsPanelInputAreaContainer;
