import { faFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faReddit } from "@fortawesome/free-brands-svg-icons/faReddit";
import { faClipboard } from "@fortawesome/free-regular-svg-icons/faClipboard";
import { faWaveform } from "@fortawesome/pro-solid-svg-icons/faWaveform";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css, StyleSheet } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import qs from "qs";
import {
  memo,
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { capitalize } from "../../utils/misc";

import eventIsFieldTrigger from "utils/misc/eventIsFieldTrigger";
import sendGAEvent from "utils/sendGAEvent";
import SharingUtils from "utils/sharing";

import useActivityContext from "hooks/useActivityContext";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import getHoverQuery from "styles/getHoverQuery";
import faXTwitter from "styles/icons/faXTwitter";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  list: {
    display: "flex",
    margin: 0,
    padding: "3px 0 0 0",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  item: {
    display: "flex",
    flex: 1,
    position: "relative",
    minWidth: "2.5em",
    maxWidth: "none",
    marginLeft: "0.5rem",
    [ScreenSizes.lgAndAbove]: {
      maxWidth: 55,
    },
  },
  link: {
    display: "flex",
    flex: 1,
    position: "relative",
    height: 0,
    paddingBottom: "100%",
    textAlign: "center",
    color: "#fff",
    cursor: "pointer",
    transition: "",

    ...getHoverQuery({
      opacity: 0.8,
      fontSize: "1.2em",
    }),
    [ScreenSizes.smAndAbove]: {
      fontSize: "1.1em",

      ...getHoverQuery({
        opacity: 0.8,
        fontSize: "1.3em",
      }),
    },
    [ScreenSizes.mdAndAbove]: {
      fontSize: "1.2em",
      // height: '2.5rem',

      ...getHoverQuery({
        opacity: 0.8,
        fontSize: "1.4em",
      }),
    },
    [ScreenSizes.lgAndAbove]: {
      maxHeight: "none",
      fontSize: "1.25rem",

      ...getHoverQuery({
        opacity: 0.8,
        fontSize: "1.4em",
      }),
    },
  },
  iconContainer: {
    display: "flex",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
  },
  icon: {
    flex: "1",
    fontSize: "1rem",
  },
  copiedNotification: {
    ...gStyles.fontBold,
    appearance: "none",
    border: "none",
    display: "block",
    background: colours.positive,
    color: "#fff",
    padding: "0.3em 0.7em",
    fontSize: "0.8em",
    margin: "0.4em 0",
  },
  copiedLink: {
    display: "block",
    background: "none",
    opacity: 0,
    padding: 0,
    margin: 0,
    height: 0,
    overflow: "hidden",
    width: "100%",
    fontSize: "0.8em",
    outline: "none",
    color: "#000",

    ":focus": {
      outline: "none",
    },
  },
  copiedLinkLabel: {
    maxWidth: 0,
    maxHeight: 0,
    overflow: "hidden",
    display: "block",
  },
  copiedLinkCopied: {
    height: "auto",
    background: "#fff",
    padding: "0.3em 0.5em 0.2em",
    margin: "0.4em 0",
    opacity: 1,
  },
  variationWhiteLink: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFF",
    fontSize: "1.3rem",
    border: `1px solid ${colours.lightishGreyBorder}`,
    height: "2.75rem",
    width: "2.75rem",
    cursor: "pointer",
  },
  description: {
    ...gStyles.fontLight,
    fontSize: ".813rem",
    lineHeight: "1.2rem",
    color: colours.bodyText,
    textAlign: "center",
    wordBreak: "breakWord",
    maxWidth: "14rem",
    padding: ".2rem .5rem",
    margin: "auto",
  },
};

const EntitySharingLinks = (props) => {
  const {
    info,
    forwardedRef,
    description,
    renderLink,
    extraShareItems,
    eventProps,
    onShare,
    itemProps,
    variation,
  } = props;

  const { styles } = useStyles(baseStyles, props);
  const copiedLinkRef = useRef(null);
  const [copied, setCopied] = useState(false);
  const [justCopied, setJustCopied] = useState(false);
  const activityContext = useActivityContext();

  const selectInputText = useCallback(() => {
    copiedLinkRef.current.select();
    copiedLinkRef.current.focus();
    copiedLinkRef.current.setSelectionRange(
      0,
      copiedLinkRef.current.value.length
    );
  }, []);

  const doCopy = useCallback(() => {
    setCopied(true);
    setJustCopied(true);
    setTimeout(() => setJustCopied(false), 5000);
    selectInputText();
  }, [selectInputText]);

  const renderCopyLink = useCallback(
    (content) => (
      <CopyToClipboard
        text={encodeURI(info.url)}
        onCopy={doCopy}
        {...itemProps}
      >
        {content}
      </CopyToClipboard>
    ),
    [doCopy, info, itemProps]
  );

  const links = useMemo(() => {
    const {
      Actions: { Facebook, NewWindowAction },
      Links: { Twitter, Reddit },
    } = SharingUtils;

    return [
      {
        title: "Share on Facebook",
        action: Facebook(info),
        icon: faFacebookF,
        color: colours.facebook,
        key: "facebook",
      },
      {
        title: "Share on X/Twitter",
        action: NewWindowAction(
          Twitter({
            ...info,
            title: info.twitterTitle || info.title,
            useVia: !info.twitterTitle && !info.noTwitterVia,
          })
        ),
        icon: faXTwitter,
        key: "twitter",
      },
      {
        title: "Share on Reddit",
        action: NewWindowAction(Reddit(info)),
        icon: faReddit,
        color: "#ff5700",
        key: "reddit",
      },
      info.headlinerInfo && {
        title: "Create Waveform on Headliner",
        url: `https://api.headliner.app/api/v1/url-generator/audio-wizard/redirect?${qs.stringify(
          {
            ...info.headlinerInfo,
            widgetKey: "wPbbKKCnziBVcmOrLE19aXKg7",
          }
        )}`,
        icon: faWaveform,
        color: "#a190fb",
        key: "headliner",
      },
      {
        title: "Copy Link",
        icon: faClipboard,
        key: "clipboard",
        render: renderCopyLink,
      },
      ...extraShareItems,
    ].filter((v) => !!v);
  }, [info, extraShareItems, renderCopyLink]);

  const handleCopiedNotificationClick = useCallback(
    () => setJustCopied(false),
    []
  );

  const handleShareClick = useCallback(
    (link) => (e) => {
      if (link.disabled) {
        e.preventDefault();
        return false;
      }
      sendGAEvent({
        action: "shareButtonClicked",
        context: "entity page sidebar",
        componentContext: "EntitySharingLinks",
        social_network: link.key,
        ...activityContext,
        ...eventProps,
      });

      if (onShare) {
        onShare(link, e);
      }

      if (link.action) {
        link.action(e);
        e.preventDefault();
        return false;
      }
    },
    [eventProps, onShare, activityContext]
  );

  const defaultLinkRender = (link) => {
    const itemStyles = StyleSheet.create({
      link:
        variation === "white"
          ? { color: link.color }
          : { backgroundColor: link.color },
    });

    return (
      <a
        href={link.url}
        onClick={handleShareClick(link)}
        onKeyDown={(e) => {
          if (eventIsFieldTrigger(e)) {
            e.preventDefault();
            handleShareClick(link)(e);
            return false;
          }
        }}
        target="_blank"
        rel="noopener noreferrer"
        className={css(
          styles.link,
          itemStyles.link,
          variation === "white" && styles.variationWhiteLink,
          link.key && styles[`link${capitalize(link.key)}`]
        )}
        style={{ backgroundColor: link.color || "#333" }}
        title={link.title}
        data-testid={`link-${link.title}`}
        {...itemProps}
      >
        <div className={css(styles.iconContainer)}>
          <FontAwesomeIcon
            className={css([
              styles.icon,
              link.key && styles[`icon${capitalize(link.key)}`],
            ])}
            icon={link.icon}
            dataid={link?.title}
          />
        </div>
      </a>
    );
  };

  const renderLinkItem = (link) => {
    const finalRenderLink = link.renderLink || renderLink || defaultLinkRender;
    let content = null;

    if (link.render) {
      content = link.render(finalRenderLink(link));
    } else {
      content = finalRenderLink(link);
    }

    return (
      <li key={link.title} className={css(styles.item)}>
        {content}
      </li>
    );
  };

  const renderDescription = () => (
    <div className={css(styles.description)}>{description}</div>
  );

  return (
    <div data-testid="entity-sharing-links" ref={forwardedRef}>
      <ul className={css(styles.list)}>{links.map(renderLinkItem)}</ul>
      <input
        id="copy-link"
        readOnly
        value={encodeURI(info.url)}
        ref={copiedLinkRef}
        className={css(styles.copiedLink, copied && styles.copiedLinkCopied)}
      />
      <label htmlFor="copy-link" className={css(styles.copiedLinkLabel)}>
        Copy Link
      </label>
      {justCopied && (
        <button
          className={css(styles.copiedNotification)}
          onClick={handleCopiedNotificationClick}
          onMouseDown={handleCopiedNotificationClick}
          onTouchStart={handleCopiedNotificationClick}
          onKeyDown={(e) => {
            if (eventIsFieldTrigger(e)) {
              handleCopiedNotificationClick(e);
            }
          }}
        >
          Copied to clipboard
        </button>
      )}
      {description && renderDescription()}
    </div>
  );
};

EntitySharingLinks.propTypes = {
  forwardedRef: PropTypes.object,
  itemProps: PropTypes.object,
  description: PropTypes.node,
  onShare: PropTypes.func,
  info: PropTypes.object,
  renderLink: PropTypes.func,
  eventProps: PropTypes.object,
  extraShareItems: PropTypes.array,
  variation: PropTypes.string,
  entity: PropTypes.instanceOf(Map),
  entity_type: PropTypes.string,
  downshiftProps: PropTypes.object,
};

EntitySharingLinks.defaultProps = {
  forwardedRef: null,
  itemProps: {},
  description: null,
  onShare: null,
  info: {},
  renderLink: null,
  eventProps: {},
  extraShareItems: [],
  variation: null,
  entity: null,
  entity_type: null,
  downshiftProps: null,
};

export default memo(
  forwardRef((props, ref) => (
    <EntitySharingLinks forwardedRef={ref} {...props} />
  ))
);
