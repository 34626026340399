import { faAngleRight } from "@fortawesome/free-solid-svg-icons/faAngleRight";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useCallback, useEffect, useMemo } from "react";

import SearchAutocomplete from "components/Search/SearchAutocomplete";

import accessibleClickProps from "../../../utils/misc/accessibleClickProps";

import paginationActions from "actions/pagination";
import * as sortConstants from "constants/sort";
import loadUsersList from "sagas/pagination/lists/loadUsersList";
import { selectSpecificUser } from "selectors/user";

import useActionCreators from "hooks/useActionCreators";
import useList from "hooks/useList";
import useLoggedInUser, { useLoggedIn } from "hooks/useLoggedInUser";
import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  searchContainer: {
    position: "relative",
    marginBottom: "1rem",
    width: "100%",

    [ScreenSizes.lgAndAbove]: {
      marginBottom: ".75rem",
    },
  },
  searchContainerWithEmail: {
    paddingRight: "1.25rem",
  },
  submitEmailNext: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 1,
    width: "1.5rem",
    backgroundColor: "#EEE",
    color: colours.midGrey,
    zIndex: 1,
    cursor: "pointer",
    borderRadius: "0px 4px 4px 0px",
  },
  searchResultUsername: {
    marginLeft: ".375rem",
    color: "#a1a1a1",
  },
  recommendationSendSuccessful: {
    ...gStyles.fontBold,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    paddingTop: 2,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: "#fefefe",
    color: colours.positive,
    border: "1px solid rgb(229, 229, 229)",
    borderRadius: 4,
    opacity: 0,
    fontSize: "0.875rem",
    pointerEvents: "none",
    transition: "opacity 500ms",
  },
  recommendationSendSuccessfulShow: {
    opacity: 1,
  },
  confirmationCheck: {
    marginRight: ".75rem",
  },
};

const searchAutoCompleteStyles = {
  autocompleteOuter: {
    padding: 0,
  },
};

const searchStyles = {
  search: {
    border: "1px solid rgb(229, 229, 229)",
    borderRadius: 4,

    [ScreenSizes.lgAndAbove]: {
      zIndex: 99,
    },
  },
  icon: {
    position: "absolute",
    left: ".875rem",
    fontSize: "1.25rem",

    [ScreenSizes.lgAndAbove]: {
      color: colours.bodyText,
    },
  },
  input: {
    ...gStyles.textEllipsis,
    ...gStyles.fontMedium,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: "3rem",
    fontSize: ".875rem",
    lineHeight: "3rem",
    height: "2.625rem",
    display: "flex",
    alignItems: "center",

    [ScreenSizes.lgAndAbove]: {
      paddingLeft: "2.75rem",
      height: "2.375rem",
      fontSize: ".75rem",
      lineHeight: "2.5rem",
    },

    "::placeholder": {
      ...gStyles.textEllipsis,
      ...gStyles.fontRegularItalic,
      fontSize: ".75rem",
      color: colours.bodyText,
    },
  },
};

const resultItemStyles = {
  resultItem: {
    minHeight: "auto",
    padding: ".5rem .4rem",
  },
  resultItemContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  resultItemImageContainer: {
    maxWidth: 35,
    height: 35,

    [ScreenSizes.lgAndAbove]: {
      maxWidth: 25,
      height: 25,
    },
  },
  resultItemImage: {
    borderRadius: "50%",
  },
  resultItemTitle: {
    fontSize: ".875rem",

    [ScreenSizes.lgAndAbove]: {
      fontSize: ".75rem",
    },
  },
  resultItemIcon: {
    fontSize: ".875rem",

    [ScreenSizes.lgAndAbove]: {
      fontSize: ".75rem",
    },
  },
};

const DirectRecommendationsPanelSearch = (props) => {
  const {
    recommendationSendSuccessful,
    searchListKey,
    isEmail,
    onSearchChange,
    onSearchTermKeyDown,
    onSelectUser,
    onSelectEmail,
    onResetRecommendation,
    search_term,
    placeholder,
    emailOnly,
  } = props;
  const { styles } = useStyles(baseStyles, props);

  const isLoggedIn = useLoggedIn();
  const loggedInUser = useLoggedInUser();

  const { loading, ids: resultIds } = useList(searchListKey);

  const results = useReduxState(
    (state) => {
      if (resultIds) {
        return resultIds.map((id) =>
          Map({
            _id: `User:${id}`,
            _type: "doc",
            _source: selectSpecificUser(state, id),
          })
        );
      }

      return [];
    },
    [resultIds]
  );

  const searchConfig = useMemo(
    () => ({
      key: searchListKey,
      sort: sortConstants.SORT_ORDER_RELEVANCE,
      list_type: "users_filtered",
      loadListAction: loadUsersList,
      entity_type: "user",
      pageSize: 10,
      staticFilters: isLoggedIn
        ? {
            following: {
              value: {
                user_by_username: loggedInUser.get("username"),
              },
            },
          }
        : {},
    }),
    [isLoggedIn, loggedInUser, searchListKey]
  );

  const { initList } = useActionCreators({
    initList: paginationActions.rawInitList,
  });

  useEffect(() => {
    initList(searchConfig);
  }, [initList, searchConfig]);

  const getUsername = useCallback(
    (userEntity) => (
      <span className={css(styles.searchResultUsername)}>
        {`@${userEntity.getIn(["_source", "username"])}`}
      </span>
    ),
    [styles.searchResultUsername]
  );

  const inputProps = useMemo(
    () => ({ onKeyDown: onSearchTermKeyDown }),
    [onSearchTermKeyDown]
  );

  const renderConfirmationMessage = () => (
    <div
      data-id="direct-recommendation-sent"
      className={css(
        styles.recommendationSendSuccessful,
        recommendationSendSuccessful && styles.recommendationSendSuccessfulShow
      )}
    >
      <FontAwesomeIcon
        icon={faCheck}
        className={css(styles.confirmationCheck)}
      />
      Recommendation sent
    </div>
  );

  return (
    <div
      data-id="direct-recommendations-panel-search"
      className={css(
        styles.searchContainer,
        isEmail && styles.searchContainerWithEmail
      )}
    >
      <SearchAutocomplete
        altSearchIcon={faEnvelope}
        ariaLabel="Search followers"
        cancelSearch={onResetRecommendation}
        getResultItemSubtitle={getUsername}
        hideHeading
        hideResults={isEmail || emailOnly}
        hideType
        inputProps={inputProps}
        noResultsMessage="No results found"
        onCloseClick={onResetRecommendation}
        onInputValueChange={onSearchChange}
        onItemSelect={onSelectUser}
        placeholder={placeholder}
        preventRedirect
        resultItemStyles={resultItemStyles}
        results={results}
        searchFieldStyles={searchStyles}
        searchMessage="Searching followers..."
        search_term={search_term}
        searching={loading}
        styles={searchAutoCompleteStyles}
        white
      />
      {isEmail && (
        <div
          data-id="direct-recommendations-email-next"
          className={css(styles.submitEmailNext)}
          {...accessibleClickProps(onSelectEmail)}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </div>
      )}
      {renderConfirmationMessage()}
    </div>
  );
};

DirectRecommendationsPanelSearch.propTypes = {
  searchListKey: PropTypes.string.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onSearchTermKeyDown: PropTypes.func.isRequired,
  onSelectUser: PropTypes.func.isRequired,
  onSelectEmail: PropTypes.func.isRequired,
  onResetRecommendation: PropTypes.func.isRequired,
  search_term: PropTypes.string,
  recommendationSendSuccessful: PropTypes.bool,
  isEmail: PropTypes.bool,
  placeholder: PropTypes.string,
  emailOnly: PropTypes.bool,
};

DirectRecommendationsPanelSearch.defaultProps = {
  recommendationSendSuccessful: false,
  isEmail: false,
  search_term: null,
  placeholder: null,
  emailOnly: false,
};

export default memo(DirectRecommendationsPanelSearch);
